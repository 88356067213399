
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import _ from "lodash";
import draggable from "vuedraggable";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "SetupAcceptableBehaviors",
  components: {
    draggable,
  },
  created() {
    this.$store.dispatch("user/loadCurrentUser");
    window.addEventListener("keydown", this.bindKeyboardShortcuts);
  },
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    error(error) {
      if (error) {
        // console.error(error);
        ElMessage.error(error);
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 3000);
      }
    },
    // loading(value) {
    //   if (value) {
    //     loadingEl = ElLoading.service({
    //       lock: true,
    //       text: "Login..",
    //       background: "#ffffff90",
    //     });
    //   } else {
    //     loadingEl && loadingEl.close();
    //   }
    // },
  },
  data() {
    return {
      step: "get_start", // get_start
      loaded: false,
      CUSTOM_FIELDS_ENABLED: true,
      CUSTOM_FIELDS_MINIMUM: 3,
      isInited: false,
      getStartedButtonLoading: false,
      canNextStepOrig: false,
      // get_start
      // Evaluating_Acceptable_Behaviors_Tutorial
      // Acceptable_Behaviors_Setup
      // setup_complete
      cloneOldSetUp: "",
      oldSetUpData: [
        {
          id: "111",
          label: "Culture Fit for Jan 2022 Batch",
        },
        {
          id: "222",
          label: "Culture Fit for feb 2022 Batch",
        },
        {
          id: "333",
          label: "Culture Fit for mar 2022 Batch",
        },
      ],
      selectedExistsTemplate: "",
      scenarioIndexShowing: 1,
      acceptableBehaviorsScenario: [] as any[],
      drag: false,
      addAccepTableModalStatus: false,
      valueInputEN: "",
      valueInputTH: "",
      // languages required
      isLangEnEnabled: true as boolean,
      isLangThEnabled: false as boolean,
      publishSubmitStatus: true,
    };
  },
  computed: {
    steps(): any[] {
      return [
        {
          step: "get_start",
          name: "Step 0",
          order: 1,
          title: "get start",
          visible: false,
        },
        {
          step: "setup_aceetable_behaivors_scenarios",
          name: "Step 1",
          order: 2,
          title: "Evaluating Acceptable Behaviors Tutorial",
          desc: "Select and manage a set of values that you would like to have available to evaluate and candidates select from.",
        },
        {
          step: "Evaluating_Acceptable_Behaviors_Tutorial",
          name: "Step 2",
          order: 3,
          title: "Evaluating Acceptable Behaviors Tutorial xxx",
          desc: "Select and manage a set of values that you would like to have available to evaluate and candidates select from.",
          visible: false,
        },
        // {
        //   step: "Acceptable_Behaviors_Setup",
        //   name: "Step 2",
        //   order: 4,
        //   title: "rate of each scenario",
        //   desc: "Please provide a rating for each scenario based on your preference and tolerance levels.",
        // },
        {
          step: "Acceptable_Behaviors_Setup_AllInOne",
          name: "Step 2",
          order: 4,
          title: "rate of each scenario",
          desc: "Please provide a rating for each scenario based on your preference and tolerance levels.",
        },
        {
          step: "setup_complete",
          name: "Step 4",
          order: 5,
          title: "setup_complete",
          visible: false,
        },
      ];
    },
    isFirstStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      return item.order == 1;
    },
    isLastStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;
      return item.order == this.steps.length;
    },
    canPreviousStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;
      return item.order > 1;
    },
    canNextStep(): boolean {
      // console.log("canNextStep()");
      // console.log(this.canNextStepOrig, "this.canNextStepOrig");
      if (this.step == "setup_aceetable_behaivors_scenarios") {
        return !this.acceptableBehaviorsScenarioDisabled;
      } else if (this.step == "Acceptable_Behaviors_Setup_AllInOne") {
        return this.canNextStepToFinish;
      }
      return true;
    },
    canNextStepToFinish(): boolean {
      const scenarios = this.acceptableBehaviors.scenarios;
      let count = 0;
      let questionTotal = 0;
      for (const i in scenarios) {
        for (const j in scenarios[i].questions) {
          questionTotal++;
          for (const k in scenarios[i].questions[j].options) {
            const o = scenarios[i].questions[j].options[k];
            if (o.checked) {
              count++;
            }
          }
        }
      }
      // console.log(`count: ${count}`);
      return count == questionTotal;
    },
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    error() {
      return this.$store.getters["assessmentSection/error"];
    },
    loading() {
      return this.$store.getters["assessmentSection/loading"];
    },
    acceptableBehaviors() {
      return this.$store.getters["assessmentSection/acceptableBehaviors"];
    },
    selectScenario() {
      let value = true;
      const scenario = this.acceptableBehaviorsScenario;
      if (scenario[this.scenarioIndexShowing - 1].value) {
        value = false;
      }
      return value;
    },
    formId() {
      return this.$store.getters["assessmentTemplate/formId"];
    },
    values() {
      return this.$store.getters["assessmentTemplate/values"];
    },
    existsTemplates() {
      return this.$store.getters["assessmentTemplate/existsTemplates"];
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    submitNewScenarioStatusBTN() {
      if (this.isLangEnEnabled && !this.valueInputEN) return false;
      if (this.isLangThEnabled && !this.valueInputTH) return false;
      return true;
    },
    acceptableBehaviorsScenarioDisabled(): boolean {
      const items = this.acceptableBehaviorsScenario;
      return items.length < this.CUSTOM_FIELDS_MINIMUM;
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
  },
  mounted() {
    const formId = this.$router.currentRoute.value.query.id;
    this.checkQuestionId();
    this.load(formId);
    this.$store.dispatch("forceReady", null, { root: true });
  },
  unmounted() {
    window.removeEventListener("keydown", this.bindKeyboardShortcuts);
  },
  methods: {
    backToLastPage() {
      // console.log("backToLastPage()");
      // this.$router.push({ name: "Assessments" });
    },
    gotoPreviousStep() {
      if (!this.canPreviousStep) return false;

      if (this.step == "Evaluating_Acceptable_Behaviors_Tutorial") {
        return this.backToStart();
      } else if (this.step == "Acceptable_Behaviors_Setup_AllInOne") {
        return this.setupPreviousStep();
      }

      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      const order = item.order - 1;
      const previousItem = _.find(this.steps, { order: order });
      if (!previousItem) return false;

      this.step = previousItem.step;
    },
    async gotoNextStep() {
      // console.log("gotoNextStep()");
      if (this.isLastStep) return;

      if (this.step == "setup_aceetable_behaivors_scenarios") {
        this.getTutorail();
        // } else if (this.step == "Evaluating_Acceptable_Behaviors_Tutorial") {
        //   return this.startSetupValues();
        // this.step = "Acceptable_Behaviors_Setup";
        this.step = "Acceptable_Behaviors_Setup_AllInOne";
        return;
      } else if (this.step == "Acceptable_Behaviors_Setup_AllInOne") {
        return this.setupNexStep();
      }
    },
    noop() {
      //
    },
    activeClass() {
      return "active";
    },
    active() {
      //
    },
    tabNextStepCls(step: string) {
      let cls = "";
      const item = _.find(this.steps, { step: step });
      if (item && item.step == this.step) {
        cls = "active";
      } else {
        const currentItem = _.find(this.steps, { step: this.step });
        if (currentItem && item) {
          if (currentItem.order > item.order) {
            cls = "passed";
          }
        }
      }

      return cls;
    },
    gotoStep($step: any) {
      console.log($step);
      return 1;
    },
    publishSubmit() {
      // console.log("publishSubmit()");
      this.publishSubmitStatus = false;
      this.closeTab();
      this.publishSubmitStatus = true;
    },
    getOptionColorByValue(value: any, active: boolean): string {
      value = parseInt(value);
      const colors = ["#9AE3BB", "#9DD2F5", "#FFE697", "#FDD29E", "#F49A9A"];
      const activeColors = [
        "#22BC66",
        "#5EB9F6",
        "#FFC200",
        "#f3722c",
        "#FF4343",
      ];

      // return active
      //   ? activeColors[value] ?? colors[0]
      //   : colors[value] ?? colors[0];

      if (active) {
        return activeColors[value] ? activeColors[value] : colors[0];
      } else {
        return colors[value] ? colors[value] : colors[0];
      }
    },
    bindKeyboardShortcuts(event: KeyboardEvent) {
      if (this.step != "Acceptable_Behaviors_Setup") return;
      const choices = ["0", "1", "2", "3", "4"];
      if (_.indexOf(choices, event.key) === -1) return;
      const scenarios = this.acceptableBehaviors.scenarios;
      const key = parseInt(event.key);
      for (const i in scenarios) {
        const scenario = scenarios[i];
        if (scenario.order == this.scenarioIndexShowing) {
          const question = scenario.questions[0];
          const options = question.options;
          for (const j in options) {
            const option = options[j];
            const value = parseInt(option.value);
            if (value == key) {
              this.onClickOption(scenario, question, option);
              break;
            }
          }
        }
      }
    },
    async initCustomFields() {
      await this.loadAcceptableBehaviors();

      const isNew = !(this.values.acceptable_behaviors_is_custom || false);
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();

      const defaultQuestion = {
        id: 1,
        index: 1,
        label: "",
        labelTH: "",
        value: "",
      };
      let acceptableBehaviorsScenario = [];
      if (isNew) {
        if (this.acceptableBehaviors) {
          for (const i in this.acceptableBehaviors.scenarios) {
            const path = "scenarios[" + i + "].questions[0]";
            const question = _.get(this.acceptableBehaviors, path);
            const order = this.acceptableBehaviors.scenarios[i].order;
            const uiQuestion = {};
            _.merge(uiQuestion, defaultQuestion, {
              id: question.id,
              index: order,
              // label: this.lang(question, "title"),
              label: this.getInputLabelValue(
                lang,
                question.title,
                question.title_th
              ),
              labelEN: question.title,
              labelTH: question.title_th,
            });
            acceptableBehaviorsScenario.push(uiQuestion);
          }
        }
      } else {
        const customFields = this.values.acceptable_behaviors_custom_fields;
        if (customFields) {
          acceptableBehaviorsScenario = customFields;
        }
      }

      // @fixed for testing
      const doTesting = false;
      if (doTesting && acceptableBehaviorsScenario) {
        this.acceptableBehaviorsScenario = acceptableBehaviorsScenario.slice(
          0,
          this.CUSTOM_FIELDS_MINIMUM
        );
        return;
      }

      this.acceptableBehaviorsScenario = acceptableBehaviorsScenario;
    },
    applyCustomFields() {
      // The easiest way is to convert this value here.

      // TODO: i18n
      const defaultQuestion = {
        checked: false,
        dirty: false,
        id: "",
        name: "",
        options: [
          { label: "Acceptable", value: 0, label_th: "ยอมรับได้" },
          { label: " Frowned Upon", label_th: "ไม่พอใจ/ไม่ยอมรับ", value: 1 },
          {
            label_th: "แสดงออกว่าไม่สนับสนุน",
            label: "Actively Discouraged",
            value: 2,
          },
          {
            label: "Reprimanded",
            value: 3,
            label_th: "ต้องมีการตำหนิหรือลงโทษ",
          },
          {
            value: 4,
            label_th: "ยอมรับไม่ได้อย่างเด็ดขาด",
            label: " Zero Tolerance Policy",
          },
        ],
        order: 1,
        title: "",
        title_th: "",
        type: "choice",
        value: "",
      };
      let newScenarios = [];
      // affected by delete or sort
      let order = 1;
      for (const i in this.acceptableBehaviorsScenario) {
        const uiQuestion = this.acceptableBehaviorsScenario[i];
        const targetQueston = { id: 1, order: 1 };
        _.merge(targetQueston, defaultQuestion, {
          id: uiQuestion.id,
          name: uiQuestion.label,
          // order: uiQuestion.index,
          order: order++,
          title: uiQuestion.label,
          title_th: uiQuestion.labelTH,
        });
        const scenario = {
          description:
            '<div class="">Please provide a rating for each scenario based on your preference and tolerance levels.</div>',
          description_th:
            '<div class="">' +
            helpers.t(
              "question.acceptableBehaviors.Please_provide_a_rating_for_each"
            ) +
            "</div>",
          id: targetQueston.id,
          name: "scenario_" + targetQueston.order,
          order: targetQueston.order,
          questions: [targetQueston],
          title: "Evaluating Acceptable Behaviors",
          title_th: helpers.t(
            "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
          ),
        };
        newScenarios.push(scenario);
      }
      newScenarios = _.sortBy(newScenarios, "order");

      this.acceptableBehaviors.scenarios = newScenarios;
      this.$store.commit(
        "assessmentSection/acceptableBehaviors",
        this.acceptableBehaviors,
        { root: true }
      );
    },
    initForms() {
      // TODO: back to start. reset it!
      // if (this.isInited) return;
      if (this.values && this.values.acceptable_behaviors) {
        const valuesMap: any = {};
        for (const i in this.values.acceptable_behaviors) {
          const item = this.values.acceptable_behaviors[i];
          if (item) {
            for (const qid in item) {
              valuesMap[qid] = item[qid];
            }
          }
        }

        if (this.acceptableBehaviors && this.acceptableBehaviors.scenarios) {
          for (const i in this.acceptableBehaviors.scenarios) {
            const scenario = this.acceptableBehaviors.scenarios[i];
            if (scenario && scenario.questions) {
              for (const j in scenario.questions) {
                const question = scenario.questions[j];
                if (question) {
                  const qid = question.id;
                  if (Object.prototype.hasOwnProperty.call(valuesMap, qid)) {
                    const value = valuesMap[qid];
                    if (Array.isArray(question.options)) {
                      const option = _.find(question.options, { value: value });
                      if (option) {
                        this.onClickOption(scenario, question, option);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // this.isInited = true;
    },
    async getStarted() {
      // console.log("getStarted()");
      if (this.getStartedButtonLoading) return;
      this.getStartedButtonLoading = true;
      window.scrollTo(0, 0);
      await this.useSelectedExistsTemplate();

      if (Array.isArray(this.values.languages_required)) {
        this.isLangEnEnabled =
          _.indexOf(this.values.languages_required, "English") !== -1;
        this.isLangThEnabled =
          _.indexOf(this.values.languages_required, "Thai") !== -1;
      }

      if (this.CUSTOM_FIELDS_ENABLED) {
        await this.initCustomFields();
        this.getStartedButtonLoading = false;
        this.step = "setup_aceetable_behaivors_scenarios";
      } else {
        this.getStartedButtonLoading = false;
        this.step = "Evaluating_Acceptable_Behaviors_Tutorial";
      }
    },
    backToStart() {
      if (this.CUSTOM_FIELDS_ENABLED) {
        this.step = "setup_aceetable_behaivors_scenarios";
      } else {
        this.step = "get_start";
      }
    },
    async getTutorail() {
      if (!this.acceptableBehaviorsScenarioDisabled) {
        this.applyCustomFields();
        this.initForms();
        // this.step = "Evaluating_Acceptable_Behaviors_Tutorial";
      }
    },
    chooseRecipe(event: any) {
      console.log(event);
    },
    updateCradList() {
      for (let i = 0; i < this.acceptableBehaviorsScenario.length; i++) {
        this.acceptableBehaviorsScenario[i].index = i + 1;
      }
    },
    getInputLabelValue(lang: string, labelEn: any, labelTh: any) {
      if (this.isLangEnEnabled && this.isLangThEnabled) {
        return lang == "en" ? labelEn : labelTh;
      } else {
        if (this.isLangEnEnabled) {
          return labelEn;
        } else if (this.isLangThEnabled) {
          return labelTh;
        }
      }
    },
    submitNewValue() {
      if (this.submitNewScenarioStatusBTN) {
        // const time = "_" + Date.now();
        const key = "custom_" + helpers.generateString(20);
        const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
        this.acceptableBehaviorsScenario.push({
          id: key,
          // label: lang == "en" ? this.valueInputEN : this.valueInputTH,
          label: this.getInputLabelValue(
            lang,
            this.valueInputEN,
            this.valueInputTH
          ),
          labelEN: this.valueInputEN,
          labelTH: this.valueInputTH,
          value: "",
          index: this.acceptableBehaviorsScenario.length + 1,
        });
      }
      this.valueInputEN = "";
      this.valueInputTH = "";
      this.addAccepTableModalStatus = false;
    },
    removeScenario(id: string) {
      ElMessageBox.confirm(
        (this as any).$t("popup.question.question_box_5.question"),
        (this as any).$t("popup.question.question_box_5.title"),
        {
          confirmButtonText: (this as any).$t("popup.optional.Confirm"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.acceptableBehaviorsScenario =
          this.acceptableBehaviorsScenario.filter(function (item) {
            return item.id !== id;
          });
      });
    },
    async useSelectedExistsTemplate() {
      if (this.selectedExistsTemplate) {
        const selectedExistsTemplate = _.find(this.existsTemplates, {
          _id: this.selectedExistsTemplate,
        });
        if (selectedExistsTemplate) {
          this.getStartedButtonLoading = true;
          const formId = this.$router.currentRoute.value.query.id;
          await this.$store.dispatch(
            "assessmentTemplate/loadFromExistsTemplate",
            {
              formId: formId,
              selectedExistsTemplate: selectedExistsTemplate,
              section: "acceptable_behaviors",
            },
            {
              root: true,
            }
          );
          this.getStartedButtonLoading = false;
        }
      }
    },
    // TODO: 15 sections make process very slow. Improve it!
    async loadAcceptableBehaviors() {
      if (!this.loaded) {
        await this.$store.dispatch(
          "assessmentSection/loadSetupAcceptableBehaviors",
          null,
          {
            root: true,
          }
        );

        // init default values for edit state
        if (this.acceptableBehaviors) {
          const hasOwnProperty = Object.prototype.hasOwnProperty;
          if (
            this.values &&
            hasOwnProperty.call(this.values, "acceptable_behaviors")
          ) {
            // Doesn't work because scenairos are changed by create, sort and/or delete
            // for the second time to call
            const history = this.values.acceptable_behaviors;
            try {
              for (const key in this.acceptableBehaviors.scenarios) {
                const scenario = this.acceptableBehaviors.scenarios[key];
                const scenarioKey = _.snakeCase(key);
                for (const i in scenario.questions) {
                  const question = scenario.questions[i];
                  const questionId = question.id;
                  const hasScenario = hasOwnProperty.call(history, scenarioKey);
                  if (hasScenario) {
                    const hasValue = hasOwnProperty.call(
                      history[scenarioKey],
                      questionId
                    );
                    if (hasValue) {
                      const value = history[scenarioKey][questionId];
                      const option = _.find(question.options, { value: value });
                      if (!option) {
                        const errorMsg = `Not found option value ${value} on scenario order ${scenario.order}`;
                        console.log(errorMsg);
                        continue;
                      }
                      this.onClickOption(scenario, question, option);
                    }
                  }
                }
              }
            } catch (e) {
              console.error(e);
            }
          }
        }

        this.loaded = true;
      }
    },
    async startSetupValues() {
      // this.step = "Acceptable_Behaviors_Setup";
      this.step = "Acceptable_Behaviors_Setup_AllInOne";
      await this.loadAcceptableBehaviors();
      this.initForms();
    },
    load(formId: any) {
      this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "acceptable_behaviors" },
        { root: true }
      );
    },
    optionCls(option: { value: any }) {
      const colors = [
        "color-15CE95",
        "color-FAA13A",
        "color-FA8B3A",
        "color-FA5F3A",
        "color-FA3A3A",
      ];
      const index = parseInt(option.value);
      return colors[index] ? colors[index] : "";
    },
    onClickOption(scenario: any, question: any, option: any) {
      // console.log("onClickOption()");
      const scenarios = this.acceptableBehaviors.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].id != scenario.id) continue;
        for (const j in scenarios[i].questions) {
          for (const k in scenarios[i].questions[j].options) {
            const o = scenarios[i].questions[j].options[k];
            o.checked = o.value == option.value;
            if (o.checked) {
              this.canNextStepOrig = true;
            }
          }
        }
      }
    },
    async finishSetup() {
      // console.log("finishSetup()");
      this.publishSubmitStatus = true;

      // setTimeout(() => {
      //   this.publishSubmitStatus = true;
      // }, 1000);
      // if (!this.publishSubmitStatus) return;

      const answers = [];
      const scenarios = this.acceptableBehaviors.scenarios;
      for (const i in scenarios) {
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              const answer = {
                id: question.id,
                value: parseInt(question.options[k].value),
              };
              answers.push(answer);
            }
          }
        }
      }
      this.$store.commit("assessmentSection/loading", true);
      let sectionAnswersDto: any = {
        _id: this.$router.currentRoute.value.query.id,
        section: this.acceptableBehaviors,
        answers: answers,
        callback: () => {
          this.$store.commit("assessmentSection/loading", false);
          this.step = "setup_complete";
        },
      };

      if (this.CUSTOM_FIELDS_ENABLED) {
        sectionAnswersDto.acceptableBehaviorsIsCustom = true;
        sectionAnswersDto.acceptableBehaviorsCustomFields =
          this.acceptableBehaviorsScenario;
      }

      this.$store.dispatch(
        "assessmentTemplate/saveSectionAnswers",
        sectionAnswersDto,
        {
          root: true,
        }
      );
      this.publishSubmitStatus = false;
    },
    applyStepperButtons() {
      // can next step if already select choice
      let isChecked = false;
      const scenarios = this.acceptableBehaviors.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].order != this.scenarioIndexShowing) continue;
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              isChecked = true;
            }
          }
        }
      }
      this.canNextStepOrig = isChecked;
    },
    checkQuestionId() {
      const id = this.$router.currentRoute.value.query.id;
      if (!id) {
        ElMessage.error((this as any).$t("popup.message.message_16e"));
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 1000);
      }
    },
    closeTab() {
      window && window.close();
    },
    setupPreviousStep() {
      const index = this.scenarioIndexShowing;
      if (index == 1) {
        // this.step = "Evaluating_Acceptable_Behaviors_Tutorial";
        this.step = "setup_aceetable_behaivors_scenarios";
      } else {
        this.scenarioIndexShowing = index - 1;
        this.applyStepperButtons();
      }
    },
    async setupNexStep() {
      // console.log("setupNexStep()");
      // console.log(this.canNextStepToFinish, "this.canNextStepToFinish");
      // if (!this.canNextStepOrig) return;
      if (!this.canNextStepToFinish) return;

      // this.scenarioIndexShowing++;
      this.scenarioIndexShowing =
        Object.keys(this.acceptableBehaviors.scenarios).length + 1;
      this.applyStepperButtons();
      if (
        this.scenarioIndexShowing >
        Object.keys(this.acceptableBehaviors.scenarios).length
      ) {
        await this.finishSetup();
      }
      // const scenario = this.acceptableBehaviorsScenario;
      // if (!this.selectScenario && scenario[this.scenarioIndexShowing]) {
      //   this.scenarioIndexShowing = this.scenarioIndexShowing + 1;
      // } else if (!this.selectScenario) {
      //   const loading = ElLoading.service({
      //     lock: true,
      //     text: "Saving..",
      //     background: "#ffffff90",
      //   });
      //   setTimeout(() => {
      //     loading.close();
      //     window.scrollTo(0, 0);
      //     this.step = "setup_complete";
      //   }, 2000);
      // }
    },
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
  },
});
